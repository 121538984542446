


















































































import {Component, Vue} from "vue-property-decorator";
import bus from "@/bus";
import UpMaterial from "@/components/UpMaterial.vue";
import Contextmenu from 'vue-context-menu'

@Component({
  components: {
    UpMaterial,
    Contextmenu
  }
})
export default class Material extends Vue {
  public MaterialGrouping = [];
  public MaterialList = [];
  public selectIndex = 0;
  public groupId = 0;
  public visible = false
  public visibleUpFile = false
  public confirmLoading = false
  public groupingName = ""
  public deleId = 0
  public pageSize = 20
  public pageNum = 1
  public totality = 0
  public files: Array<File> = []
  public deleMaterialId: Array<string> = []
  public okText = "上传"
  public isOk = false

  mounted() {
    this.getGrouping()
  }

  public onContextShow(data) {
    this.deleId = data;
    (this.$refs.contextmenu as any).open()
  }

  // 删除分组
  public onDeleteGrouping() {
    bus.$post("/material/removeTemplateGroup", {
      materialGroupId: this.deleId
    }).then(res => {
      this.getGrouping()
      bus.$elementMessage("删除成功", "error")
    }).catch(err => {
      bus.$elementMessage(err, "error")
    })
  }

  // 获取素材
  public getMaterial(groupId) {
    this.groupId = groupId
    bus.$post('/material/getMaterialList', {
      "pageSize": this.pageSize,
      "pageNum": this.pageNum,
      groupId
    }).then(res => {
      this.MaterialList = res.data.list
      this.totality = res.data.totalNum
      this.MaterialList.forEach(res => {
        res.selete = false
      })
    }).catch(err => {
      bus.$elementMessage(err, 'error')
    })
  }

  // 点击分组
  public seleteMaterial(index, id) {
    this.selectIndex = index
    this.getMaterial(id)
  }

  // 获取分组
  public getGrouping() {
    bus.$post('/material/getMaterialGroupList').then(res => {
      this.MaterialGrouping = res.data.groupList
      if (this.MaterialGrouping.length) {
        this.getMaterial(this.MaterialGrouping[0].materialGroupId)
      }

    }).catch(err => {
      bus.$elementMessage(err, 'error')
    })
  }

  // 添加分组
  public addGrouping() {
    this.visible = true;
  }

  // 提交分组
  public handleOk(e) {
    bus.$post("/material/addMaterialGroup", {
      groupName: this.groupingName
    }).then(res => {
      bus.$elementMessage(res.msg, 'success')
      this.visible = false
      this.getGrouping()
    }).catch(err => {
      bus.$elementMessage(err, "error")
    })
  }

  // 取消分组
  public handleCancel(e) {
    this.groupingName = ""
    this.visible = false;

  }

  // 选择素材
  public openFile() {
    (this.$refs.seleFile as any).click()
  }

  // 打开控制器
  public seleFile(file) {
    this.files = (this.$refs.seleFile as any).files
    this.visibleUpFile = true
  }

  // 上传
  public handleFile() {
    (this.$refs.UpMaterial as any).allFiles()
    this.isOk = true
  }

  // 选择删除的素材
  onChange(e, index) {
    bus.$set(this.MaterialList, index, this.MaterialList[index])
    this.deleMaterialId = this.MaterialList.filter(res => res.selete)
  }

  // 删除素材
  public deleteMaterial() {
    if (!this.deleMaterialId.length) {
      bus.$elementMessage("请选择素材", 'error')
      return
    }

    this.$confirm({
      title: 'Are you sure delete this task?',
      content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        bus.$post('/material/removeMaterial', {
          materialIds: this.deleMaterialId.map(res => (res as any).materialId)
        }).then(res => {
          bus.$elementMessage(res.msg, 'success')
          this.deleMaterialId = []
          this.getMaterial(this.groupId)
        }).catch(err => {
          bus.$elementMessage(err, 'error')
        })
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  // 上传完毕
  public finish() {
    this.okText = "上传完毕"
    this.getMaterial(this.groupId)
  }

  // 关闭弹窗
  public handleCancelUp() {
    this.visibleUpFile = false
  }

  // 分页
  onShowSizeChange(current) {
    this.pageNum = current
  }

}
