













import bus from "@/bus";
import {Component, Prop, Vue} from "vue-property-decorator";
import instance from "@/axios";

@Component
export default class UpMaterial extends Vue {
  public iterator: Iterator<any> = null
  public percent = 0

  @Prop({type: Number}) groupId
  @Prop({type: FileList}) files

  // 上传素材
  public upFile(file) {
    let form = new FormData()
    form.append('file', file)
    form.append('groupId', this.groupId)

    instance({
      url: "/material/addMaterial",
      method: 'post',
      data: form,
      onUploadProgress: (e) => {
        this.percent = parseInt((e.loaded / e.total * 100).toString())
      }
    }).then(res => {
      if (!this.iterator.next().done) {
        this.iterator.next()
      } else {
        this.$emit("finish")
      }
    }).catch(err => {
      bus.$elementMessage(err, 'error')
    })

  }

  public* createIterator() {
    for (const file of this.files) {
      yield this.upFile(file)
    }
  }

  public allFiles() {
    this.iterator = this.createIterator()
    this.iterator.next()
  }

}
